import React from "react"
import "../styles/styles.scss"
import ALSPageComponent from "../components/alsPageComponent"
//import LsPageComponent from "../components/lsPageComponent"
import { Helmet } from "react-helmet"

const LSPage = () => (
  
  <div>
  <Helmet
    title="Security-Alarm-KMU" defer={false}>
      <html lang="de" />
      <meta name="description" content="ALARM-Projekt -- Analoge Lernszenarien" />
      <meta name="keywords" content="Game, Security, ALARM, Awareness, KMU" />
  </Helmet>
    <ALSPageComponent
      ls="5" 
      game="als5"
      motto="Social Engineering & Cyber Pairs"
      moderation="Als05OModeration"
      handout="Als05OHandout"
      konstruktion="Als05OKonstruktion"
      vorlage="Als05ODruckvorlagen"/>
  </div>
)

export default LSPage
